import Axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { GlobalContext } from "../context/Context";
import Select from "react-select";

const SessionCareBilling = () => {
  const {
    showNotification,
    setShowNotification,
    notificationMessage,
    setNotificationMessage,
    setLink,
    modal,
    setModal,
  } = useContext(GlobalContext);

  const [sessionCareCustomerID, setsessionCareCustomerID] = useState("");
  // const [showNotification, setShowNotification] = useState(false);
  // const [notificationMessage, setNotificationMessage] = useState("");
  // const [accessCode, setAccessCode] = useState("");
  const history = useHistory();

  const options = [
    { value: "live", label: "Live" },
    { value: "staging", label: "Staging" },
    { value: "develop", label: "Develop" },
  ];

  const [environment, setEnvironment] = useState("live");
  const [backendURL, setBackendURL] = useState(
    "https://billing-api.sessioncare.com"
  );

  const [frontendURL, setFrontendURL] = useState("billing.sessioncare.com");

  const handleOnClick = () => {
    if (sessionCareCustomerID === "") {
      setNotificationMessage("Please enter an ID below to run iFrame");
      setShowNotification(true);
    } else {
      Axios.get(backendURL + "/api/portal" + `/${sessionCareCustomerID}`, {
        headers: {
          api_key:
            environment === "live"
              ? "sessioncare_12c17a8cb23f432d9f355e4ee0543b25"
              : "sessioncare_3752478a7ae841d5bab5dfdd09edc9d8",
        },
      })
        .then(({ data }) => {
          console.log(data);
          setNotificationMessage("Access code acquired! Running iFrame");
          setShowNotification(true);
          setLink(data.link);
          setModal(!modal);
          history.push(`/${environment}`);
        })
        .catch((err) => {
          setNotificationMessage(err.response.data.error_message);
          setShowNotification(true);
        });
    }
  };

  const handleOnChange = (event) => {
    setsessionCareCustomerID(event.target.value);
  };

  const onEnvironmentChanged = (target) => {
    console.log(target.value);
    if (target.value === "live") {
      setBackendURL("https://billing-api.sessioncare.com");
      setFrontendURL("https://billing.sessioncare.com");
    } else if (target.value === "staging") {
      setBackendURL("https://sessioncare-backend-staging.onrender.com");
      setFrontendURL("https://sessioncare-billing-staging-fe.onrender.com");
    } else {
      setBackendURL("https://sessioncare-backend-dev.onrender.com");
      setFrontendURL("https://session-care-billing-dev-fe.onrender.com");
    }

    setEnvironment(target.value);
  };

  return (
    <div className="page-body">
      <h1 className="page-title">SessionCare Billing iFrame Tester</h1>

      <br />
      <Link to="/" className="back-link">
        Return Home
      </Link>

      {showNotification && (
        <div className="notifications">{notificationMessage}</div>
      )}

      <div className="form-body">
        <div className="form-heading">
          Enter Session Care Customer ID to enable iFrame:
        </div>
        <div className="form-group">
          <div className="form-label">Session Care Customer ID</div>
          <div className="form-input-holder">
            <input
              type="text"
              className="form-input-text"
              onChange={handleOnChange}
              name="sessionCareCustomerID"
              value={sessionCareCustomerID}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-label">Choose Environment:</div>
          <Select
            onChange={onEnvironmentChanged}
            options={options}
            className="form-input-select"
          />
        </div>

        <button onClick={handleOnClick} className="form-btn-submit">
          Run iFrame
        </button>
      </div>
    </div>
  );
};

export default SessionCareBilling;
