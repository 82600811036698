import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../context/Context";
import { Modal, ModalBody } from "reactstrap";

import "bootstrap/dist/css/bootstrap.min.css";

const IFrame = () => {
  const { showNotification, notificationMessage, link, modal } = useContext(
    GlobalContext
  );

  useEffect(() => {
    console.log(link);
  }, []);

  return (
    <React.Fragment>
      {link ? (
        <Modal isOpen={modal}>
          {showNotification ? (
            <div className="notifications">{notificationMessage}</div>
          ) : (
            <ModalBody>
              <iframe
                className="iframe-wrapper"
                src={link}
                title="Iframe Example"
              ></iframe>
            </ModalBody>
          )}
        </Modal>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default IFrame;
