import Axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";

const Register = () => {
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "@fullstack.com",
    companyName: "fullstackhq",
    billingAddressLine1: "ABC 123 Address",
    billingAddressLine2: "",
    billingCity: "XYZ City",
    billingState: "AB",
    billingCountry: "USA",
    billingPostCode: "123456",
    sessionCareCustomerID: uuidv4(),
  });

  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [environment, setEnvironment] = useState("live");
  const [backendURL, setBackendURL] = useState(
    "https://billing-api.sessioncare.com/api/customers"
  );

  const options = [
    { value: "live", label: "Live" },
    { value: "staging", label: "Staging" },
    { value: "develop", label: "Develop" },
  ];

  const handleOnClick = () => {
    console.log("register user");
    Axios.post(backendURL, formValue, {
      headers: {
        api_key:
          environment === "live"
            ? "sessioncare_12c17a8cb23f432d9f355e4ee0543b25"
            : "sessioncare_3752478a7ae841d5bab5dfdd09edc9d8",
      },
    })
      .then(({ data }) => {
        console.log(data);
        setNotificationMessage(
          "Your information was SAVED successfully!!! Here is your ID: " +
            formValue.sessionCareCustomerID
        );
        setShowNotification(true);
      })
      .catch((error) => {
        if (
          error.response.data.error_message !== undefined ||
          error.response.data.error_message === null
        ) {
          console.log(error.response.data.error_message);

          setNotificationMessage(
            "FAILED saving your information. " +
              error.response.data.error_message
          );
        } else {
          console.log(error.response.data);
          setNotificationMessage(
            "FAILED saving your information. " +
              error.response.data.error_messages
          );
        }
        setShowNotification(true);
      });
  };

  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setShowNotification(false);
      }, 10000);
    }
  }, [showNotification]);

  const handleOnChange = (event) => {
    setFormValue({ ...formValue, [event.target.name]: event.target.value });
  };

  const onEnvironmentChanged = (target) => {
    console.log(target.value);
    if (target.value === "live")
      setBackendURL("https://billing-api.sessioncare.com/api/customers");
    else if (target.value === "staging")
      setBackendURL(
        "https://sessioncare-backend-staging.onrender.com/api/customers"
      );
    else
      setBackendURL(
        "https://sessioncare-backend-dev.onrender.com/api/customers"
      );

    setEnvironment(target.value);
  };

  return (
    <div className="page-body">
      <h1 className="page-title">Register</h1>

      <br />
      <Link to="/" className="back-link">
        Return Home
      </Link>

      {showNotification && (
        <div className="notifications">{notificationMessage}</div>
      )}

      <div className="form-body">
        <div className="form-heading">Personal Info:</div>
        <div className="form-group">
          <div className="form-label">Session Care Customer ID</div>
          <div className="form-input-holder">
            <input
              type="text"
              className="form-input-text"
              disabled
              value={formValue.sessionCareCustomerID}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-label">First Name</div>
          <div className="form-input-holder">
            <input
              type="text"
              onChange={handleOnChange}
              name="firstName"
              className="form-input-text"
              value={formValue.firstName}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-label">Last Name</div>
          <div className="form-input-holder">
            <input
              type="text"
              onChange={handleOnChange}
              name="lastName"
              className="form-input-text"
              value={formValue.lastName}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-label">Email Address</div>
          <div className="form-input-holder">
            <input
              type="email"
              onChange={handleOnChange}
              name="email"
              className="form-input-text"
              value={formValue.email}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-label">Company Name</div>
          <div className="form-input-holder">
            <input
              type="text"
              onChange={handleOnChange}
              name="companyName"
              className="form-input-text"
              value={formValue.companyName}
            />
          </div>
        </div>

        <div className="form-heading">Address:</div>

        <div className="form-group">
          <div className="form-label">Biling Address Line 1</div>
          <div className="form-input-holder">
            <input
              type="text"
              onChange={handleOnChange}
              name="billingAddressLine1"
              className="form-input-text"
              value={formValue.billingAddressLine1}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-label">Biling Address Line 2</div>
          <div className="form-input-holder">
            <input
              type="text"
              onChange={handleOnChange}
              name="billingAddressLine2"
              className="form-input-text"
              value={formValue.billingAddressLine2}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-label">Billing City</div>
          <div className="form-input-holder">
            <input
              type="text"
              onChange={handleOnChange}
              name="billingCity"
              className="form-input-text"
              value={formValue.billingCity}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-label">Biling State</div>
          <div className="form-input-holder">
            <input
              type="text"
              onChange={handleOnChange}
              name="billingState"
              className="form-input-text"
              value={formValue.billingState}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-label">Biling Country</div>
          <div className="form-input-holder">
            <input
              type="text"
              onChange={handleOnChange}
              name="billingCountry"
              className="form-input-text"
              value={formValue.billingCountry}
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-label">Biling Post Code</div>
          <div className="form-input-holder">
            <input
              type="text"
              onChange={handleOnChange}
              name="billingPostCode"
              className="form-input-text"
              value={formValue.billingPostCode}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-label">Save to Environment:</div>
          <Select
            onChange={onEnvironmentChanged}
            options={options}
            className="form-input-select"
          />
        </div>

        <button onClick={handleOnClick} className="form-btn-submit">
          Create User
        </button>
      </div>
    </div>
  );
};

export default Register;
