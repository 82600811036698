import React, { useState, useEffect } from "react";

export const GlobalContext = React.createContext();

export const GlobalContextProvider = ({ children }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [link, setLink] = useState("");
  const [modal, setModal] = useState("");

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (showNotification) {
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  }, [showNotification]);

  return (
    <GlobalContext.Provider
      value={{
        showNotification,
        setShowNotification,
        notificationMessage,
        setNotificationMessage,
        link,
        setLink,
        modal,
        setModal,
        toggle,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
