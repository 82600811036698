import "./styles/main.scss";
import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";

import Index from "./page/Index";
import Register from "./page/Register";
import SessionCareBilling from "./page/SessionCareBilling";
import IFrame from "./page/iFrame";

import GlobalContextProvider from "./context/Context";

function App() {
  return (
    <Router history={history}>
      <GlobalContextProvider>
        <div className="App">
          <div className="custom-container">
            <div className="app-wrapper">
              <Switch>
                <Route path={["/", "/home"]} exact component={Index} />
                <Route path="/register" component={Register} />
                <Route
                  path="/sessioncare-billing"
                  component={SessionCareBilling}
                />
                <Route path="/:environment" component={IFrame} />
              </Switch>
            </div>
          </div>
        </div>
      </GlobalContextProvider>
    </Router>
  );
}

export default App;
