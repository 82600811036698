import React from "react";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <div className="index-body">
      <div className="index-btn-group">
        <Link to="/register" className="btn-plain">
          Register Customer
        </Link>

        <Link to="/sessioncare-billing" className="btn-plain">
          Session Care Billing iFrame tester
        </Link>
      </div>
    </div>
  );
};

export default Index;
